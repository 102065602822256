$(function () {
  if ($("#trading").length) {
    const TRADING = {
      assets: undefined,
      assetsTable: $("#assets-table").DataTable({
        columns: [
          {
            data: "currency",
          },
          {
            data: "type",
          },
          {
            data: "balance",
          },
          {
            data: "available",
            fnCreatedCell: function (nTd, sData, oData, _iRow) {
              $(nTd).html(
                `<a href='#' class="inner-transfer" data-available="${oData["available"]}" data-currency="${oData["currency"]}" data-type="${oData["type"]}">${sData}</a>`
              );
            },
          },
          {
            data: "holds",
          },
          {
            data: "usd_value",
            title: "$",
            fnCreatedCell: function (nTd, sData, _oData, _iRow) {
              $(nTd).html(document.amountToCurrency(sData, 1));
            },
          },
        ],
        dom: '<"top"i>frtp',
        order: [[0, "asc"]],
        ordering: true,
        paging: false,
        createdRow: function (row, _data) {
          $(row).addClass("align-baseline");
        },
        drawCallback: function () {
          $("#assets-table").removeClass("gy-5");
          $(".inner-transfer").on("click", function (e) {
            document.innerTransferForm.currency.value =
              e.currentTarget.dataset.currency;
            document.innerTransferForm.from.value =
              e.currentTarget.dataset.type;
            if (e.currentTarget.dataset.type == "trade") {
              document.innerTransferForm.to.value = "isolated";
            } else {
              document.innerTransferForm.to.value = "trade";
            }
            document.innerTransferForm.size.value =
              e.currentTarget.dataset.available;
            if (e.currentTarget.dataset.type == "isolated") {
              let isolatedAsset = window._.find(
                TRADING.isolatedAssets,
                function (o) {
                  return (
                    o["baseAsset"]["available"] ==
                      e.currentTarget.dataset.available &&
                    o["baseAsset"]["currency"] ==
                      e.currentTarget.dataset.currency
                  );
                }
              );
              document.innerTransferForm.fromTag.value = isolatedAsset.symbol;
              document.innerTransferForm.toTag.value = undefined;
            } else {
              document.innerTransferForm.fromTag.value = undefined;
            }
            $("#inner-transfer-modal").modal("show");
          });
        },
      }),
      isolatedAssets: undefined,
      isolatedTable: $("#isolated-table").DataTable({
        columns: [
          {
            data: "symbol",
            fnCreatedCell: function (nTd, _sData, _oData, _iRow) {
              $(nTd).addClass("fw-bold");
            },
          },
          {
            data: "debtRatio",
            fnCreatedCell: function (nTd, sData, _oData, _iRow) {
              if (Number(sData) >= 0.87) {
                $("[href='#tab-isolated']").addClass("bg-danger");
              }
              $(nTd).addClass("border-end");
            },
          },
          {
            data: "baseAsset.currency",
            title: "Base",
            fnCreatedCell: function (nTd, _sData, _oData, _iRow) {
              $(nTd).addClass("fw-bold");
            },
          },
          {
            data: "baseAsset.liability",
            title: "Liability",
            fnCreatedCell: function (nTd, sData, oData, _iRow) {
              $(nTd).html(
                `<a href='#' class="liability" data-symbol="${oData["symbol"]}" data-asset-side="baseAsset">${sData}</a>`
              );
            },
          },
          {
            data: "baseAsset.total",
            title: "Total",
          },
          {
            data: "baseAsset.available",
            title: "Available",
          },
          {
            data: "baseAsset.hold",
            title: "Hold",
          },
          {
            data: "baseAsset.maxBorrowSize",
            title: "Borrowable",
            fnCreatedCell: function (nTd, sData, oData, _iRow) {
              $(nTd).addClass("border-end");
              $(nTd).html(
                `<a href='#' class="borrowable" data-symbol="${oData["symbol"]}" data-asset-side="baseAsset">${sData}</a>`
              );
            },
          },
          {
            data: "quoteAsset.currency",
            title: "Quote",
            fnCreatedCell: function (nTd, _sData, _oData, _iRow) {
              $(nTd).addClass("fw-bold");
            },
          },
          {
            data: "quoteAsset.liability",
            title: "Liability",
            fnCreatedCell: function (nTd, sData, oData, _iRow) {
              $(nTd).html(
                `<a href='#' class="liability" data-symbol="${oData["symbol"]}" data-asset-side="quoteAsset">${sData}</a>`
              );
            },
          },
          {
            data: "quoteAsset.total",
            title: "Total",
          },
          {
            data: "quoteAsset.available",
            title: "Available",
          },
          {
            data: "quoteAsset.hold",
            title: "Hold",
          },
          {
            data: "quoteAsset.maxBorrowSize",
            title: "Borrowable",
            fnCreatedCell: function (nTd, sData, oData, _iRow) {
              $(nTd).html(
                `<a href='#' class="borrowable" data-symbol="${oData["symbol"]}" data-asset-side="quoteAsset">${sData}</a>`
              );
            },
          },
        ],
        dom: '<"top"i>frtp',
        order: [[0, "asc"]],
        ordering: true,
        paging: false,
        createdRow: function (row, _data) {
          $(row).addClass("align-baseline");
        },
        drawCallback: function () {
          $("#isolated-table").removeClass("gy-5");
          $(".borrowable").on("click", function (e) {
            let isolatedAsset = window._.find(
              TRADING.isolatedAssets,
              function (o) {
                return o["symbol"] == e.currentTarget.dataset.symbol;
              }
            );
            document.borrowForm.symbol.value = isolatedAsset["symbol"];
            document.borrowForm.currency.value =
              isolatedAsset[e.currentTarget.dataset.assetSide]["currency"];
            document.borrowForm.size.value =
              isolatedAsset[e.currentTarget.dataset.assetSide]["maxBorrowSize"];
            $("#borrow-modal").modal("show");
          });
          $(".liability").on("click", function (e) {
            let isolatedAsset = window._.find(
              TRADING.isolatedAssets,
              function (o) {
                return o["symbol"] == e.currentTarget.dataset.symbol;
              }
            );
            document.repayForm.symbol.value = isolatedAsset["symbol"];
            document.repayForm.currency.value =
              isolatedAsset[e.currentTarget.dataset.assetSide]["currency"];
            document.repayForm.size.value =
              isolatedAsset[e.currentTarget.dataset.assetSide]["available"];
            $("#repay-modal").modal("show");
          });
        },
      }),
      iptacrTable: $("#iptacr-table").DataTable({
        columns: [
          {
            data: "datetime",
            title: "Datetime",
            fnCreatedCell: function (nTd, sData, _oData, _iRow) {
              let date = new Date(sData);
              let dateFormatted = `${date
                .getDate()
                .toString()
                .padStart(2, "0")}/${(date.getMonth() + 1)
                .toString()
                .padStart(2, "0")} ${date
                .getHours()
                .toString()
                .padStart(2, "0")}:${date
                .getMinutes()
                .toString()
                .padStart(2, "0")}`;
              $(nTd).html(dateFormatted);
            },
          },
          {
            data: "interval_pool_trading_alert_condition.interval_pool.pool.third_party_identifier",
            title: "Symbol",
          },
          {
            data: "interval_pool_trading_alert_condition.interval_pool.interval",
            title: "Interval",
            fnCreatedCell: function (nTd, sData, _oData, _iRow) {
              $(nTd).html(`${sData.number_of_units} ${sData.unit}`);
            },
          },
          {
            data: "interval_pool_trading_alert_condition.trading_alert_condition.action",
            title: "Alert",
            fnCreatedCell: function (nTd, sData, oData, _iRow) {
              $(nTd).html(`${oData.tide} ${sData}`);
            },
          },
          {
            data: "tracking",
            title: "Tracking",
            fnCreatedCell: function (nTd, sData, oData, _iRow) {
              let iconClass = "bi-x-lg";
              let btnColorClass = "btn-secondary";
              if (sData) {
                iconClass = "bi-check-lg";
                btnColorClass = "btn-success";
              }
              $(nTd).html(
                `<a href="#" class="btn btn-sm btn-icon ${btnColorClass} toggling-button" data-iptrac-id="${oData.id}" data-iptrac-toggling-param="tracking" data-iptrac-toggling-value="${sData}"><div class="d-none loading"><em aria-hidden="true" class="spinner-grow spinner-grow-sm" role="status"></em></div><div class="ready"><em class="bi ${iconClass}"></em></div></a>`
              );
            },
          },
          {
            data: "checked",
            title: "Checked",
            fnCreatedCell: function (nTd, sData, oData, _iRow) {
              let iconClass = "bi-x-lg";
              let btnColorClass = "btn-secondary";
              if (sData) {
                iconClass = "bi-check-lg";
                btnColorClass = "btn-success";
              }
              $(nTd).html(
                `<a href="#" class="btn btn-sm btn-icon ${btnColorClass} toggling-button" data-iptrac-id="${oData.id}" data-iptrac-toggling-param="checked" data-iptrac-toggling-value="${sData}"><div class="d-none loading"><em aria-hidden="true" class="spinner-grow spinner-grow-sm" role="status"></em></div><div class="ready"><em class="bi ${iconClass}"></em></div></a>`
              );
            },
          },
          {
            data: "ignore",
            title: "Ignore",
            fnCreatedCell: function (nTd, sData, oData, _iRow) {
              let iconClass = "bi-x-lg";
              let btnColorClass = "btn-secondary";
              if (sData) {
                iconClass = "bi-check-lg";
                btnColorClass = "btn-success";
              }
              $(nTd).html(
                `<a href="#" class="btn btn-sm btn-icon ${btnColorClass} toggling-button" data-iptrac-id="${oData.id}" data-iptrac-toggling-param="ignore" data-iptrac-toggling-value="${sData}"><div class="d-none loading"><em aria-hidden="true" class="spinner-grow spinner-grow-sm" role="status"></em></div><div class="ready"><em class="bi ${iconClass}"></em></div></a>`
              );
            },
          },
          {
            data: "id",
            title: "",
            searchable: false,
            fnCreatedCell: function (nTd, sData, _oData, _iRow) {
              $(nTd).html(
                `<div class="d-flex"><a href="/admin/interval_pool_trading_alert_condition_records/${sData}/edit" target="_blank" class="btn btn-sm btn-icon btn-secondary"><em class="bi bi-pencil-square"></em></a></div>`
              );
            },
          },
        ],
        dom: '<"top"i>frtp',
        order: [[0, "desc"]],
        ordering: true,
        paging: false,
        createdRow: function (row, _data) {
          $(row).addClass("align-baseline");
        },
        drawCallback: function () {
          $("#iptacr-table").removeClass("gy-5");
          $(".toggling-button").on("click", async (event) => {
            event.preventDefault();
            try {
              document.disableButton(event.currentTarget);
              let togglingParam = $(event.currentTarget).data(
                "iptracTogglingParam"
              );
              let data = {
                interval_pool_trading_alert_condition_record: {},
              };
              let newValue = !$(event.currentTarget).data(
                "iptracTogglingValue"
              );
              if (togglingParam == "tracking") {
                data.interval_pool_trading_alert_condition_record.tracking =
                  newValue;
              } else if (togglingParam == "checked") {
                data.interval_pool_trading_alert_condition_record.checked =
                  newValue;
              } else if (togglingParam == "ignore") {
                data.interval_pool_trading_alert_condition_record.ignore =
                  newValue;
              }
              let response = await $.ajax({
                url: `/interval_pool_trading_alert_condition_records/${$(
                  event.currentTarget
                ).data("iptracId")}`,
                type: "put",
                data,
                dataType: "json",
              });
              if (togglingParam == "tracking") {
                newValue = response["tracking"];
              } else if (togglingParam == "checked") {
                newValue = response["checked"];
              } else if (togglingParam == "ignore") {
                newValue = response["ignore"];
              }
              if (newValue) {
                $(event.currentTarget)
                  .find(".ready .bi")
                  .removeClass("bi-x-lg");
                $(event.currentTarget)
                  .find(".ready .bi")
                  .addClass("bi-check-lg");
                $(event.currentTarget).addClass("btn-success");
                $(event.currentTarget).removeClass("btn-secondary");
              } else {
                $(event.currentTarget).find(".ready .bi").addClass("bi-x-lg");
                $(event.currentTarget)
                  .find(".ready .bi")
                  .removeClass("bi-check-lg");
                $(event.currentTarget).removeClass("btn-success");
                $(event.currentTarget).addClass("btn-secondary");
              }
              $(event.currentTarget).data("iptracTogglingValue", newValue);
            } catch (err) {
              document.showAlertDanger(err);
            } finally {
              document.enableButton(event.currentTarget);
            }
          });
        },
      }),
      updateIntervalPoolTradingAlertConditionRecords: undefined,
      updateAnchorToAnchorTable: undefined,
      updateAssetsTable: undefined,
      updateChallengeTable: undefined,
      updateIntervaleStatsTable: undefined,
      updateIsolatedTable: undefined,
      updateTable: undefined,
      init: async () => {
        $("#kt_app_content").removeClass("container-xxl");
        $("#play-sound").on("click", function (e) {
          e.preventDefault();
          $("#play-sound").addClass("d-none");
          $("#bird-audio").removeClass("d-none");
        });

        document.borrowForm.onsubmit = async (e) => {
          e.preventDefault();
          document.disableButton(
            'form[name="borrowForm"] button[type="submit"]'
          );
          try {
            await $.ajax({
              type: "post",
              url: "credentials/5/borrow",
              contentType: "application/json; charset=utf-8",
              data: JSON.stringify({
                currency: document.borrowForm.currency.value,
                size: document.borrowForm.size.value,
                is_isolated: true,
                symbol: document.borrowForm.symbol.value,
              }),
            });
            await TRADING.refreshAssetsTable();
            await TRADING.refreshIsolatedTable();
            $("#borrow-modal").modal("hide");
            document.showAlertSuccess("Borrow successful.");
          } catch (error) {
            document.showAlertDanger(error);
          } finally {
            document.enableButton(
              'form[name="borrowForm"] button[type="submit"]'
            );
          }
        };

        document.repayForm.onsubmit = async (e) => {
          e.preventDefault();
          document.disableButton(
            'form[name="repayForm"] button[type="submit"]'
          );
          try {
            await $.ajax({
              type: "post",
              url: "credentials/5/repay",
              contentType: "application/json; charset=utf-8",
              data: JSON.stringify({
                currency: document.repayForm.currency.value,
                size: document.repayForm.size.value,
                is_isolated: true,
                symbol: document.repayForm.symbol.value,
              }),
            });
            await TRADING.refreshAssetsTable();
            await TRADING.refreshIsolatedTable();
            $("#repay-modal").modal("hide");
            document.showAlertSuccess("Repay successful.");
          } catch (error) {
            document.showAlertDanger(error);
          } finally {
            document.enableButton(
              'form[name="repayForm"] button[type="submit"]'
            );
          }
        };

        document.innerTransferForm.onsubmit = async (e) => {
          e.preventDefault();
          document.disableButton(
            'form[name="innerTransferForm"] button[type="submit"]'
          );
          try {
            await $.ajax({
              type: "post",
              url: "credentials/5/inner_transfer",
              contentType: "application/json; charset=utf-8",
              data: JSON.stringify({
                currency: document.innerTransferForm.currency.value,
                from: document.innerTransferForm.from.value,
                to: document.innerTransferForm.to.value,
                size: document.innerTransferForm.size.value,
                from_tag: document.innerTransferForm.fromTag.value,
                to_tag: document.innerTransferForm.toTag.value,
              }),
            });
            await TRADING.refreshAssetsTable();
            await TRADING.refreshIsolatedTable();
            $("#inner-transfer-modal").modal("hide");
            document.showAlertSuccess("Transfer successful.");
          } catch (error) {
            document.showAlertDanger(error);
          } finally {
            document.enableButton(
              'form[name="innerTransferForm"] button[type="submit"]'
            );
          }
        };

        await TRADING.refreshAnchorToAnchorTable();
        TRADING.refreshAnchorToAnchorTableInterval();
        await TRADING.refreshAssetsTable();
        TRADING.refreshAssetsTableInterval();
        await TRADING.refreshIsolatedTable();
        TRADING.refreshIsolatedTableInterval();
        await TRADING.refreshTable();
        TRADING.refreshTableInterval();
        await TRADING.refreshIntervalPoolTradingAlertConditionRecords();
        TRADING.refreshIntervalPoolTradingAlertConditionRecordsInterval();
        await TRADING.refreshIntervalStatsTable();
        TRADING.refreshIntervalStatsTableInterval();
        await TRADING.refreshChallengeTable();
        TRADING.refreshChallengeTableInterval();
      },
      refreshAnchorToAnchorTable: async () => {
        try {
          $.ajax({
            url: `/interval_pools/anchor_to_anchor_table`,
          });
        } catch (err) {
          document.showAlertDanger(err);
        }
      },
      refreshAnchorToAnchorTableInterval: () => {
        clearInterval(TRADING.updateAnchorToAnchorTable);
        TRADING.updateAnchorToAnchorTable = setInterval(function () {
          TRADING.refreshAnchorToAnchorTable();
        }, 15 * 60_000);
      },
      refreshIntervalPoolTradingAlertConditionRecords: async () => {
        try {
          let response = await $.ajax({
            url: `/interval_pool_trading_alert_condition_records`,
          });
          TRADING.iptacrTable.clear();
          TRADING.iptacrTable.rows.add(response);
          TRADING.iptacrTable.columns.adjust().draw();
          let playSound = false;
          response.forEach(function (iptacr) {
            if (!playSound && !iptacr.checked) {
              playSound = true;
            }
          });
          if (playSound && !$("#bird-audio").hasClass("d-none")) {
            $("#bird-audio audio")[0].play();
          }
        } catch (err) {
          document.showAlertDanger(err);
        }
      },
      refreshChallengeTable: async () => {
        try {
          $.ajax({
            url: `/balances/challenge_table`,
          });
        } catch (err) {
          document.showAlertDanger(err);
        }
      },
      refreshIsolatedTable: async () => {
        try {
          $("[href='#tab-isolated']").removeClass("bg-danger");
          let response = await $.ajax({
            url: `/credentials/5/funding_isolated`,
          });
          TRADING.isolatedAssets = response.assets;
          TRADING.isolatedTable.clear();
          TRADING.isolatedTable.rows.add(response.assets);
          TRADING.isolatedTable.columns.adjust().draw();
          let netIsolatedValue =
            Number(response["totalAssetOfQuoteCurrency"]) -
            Number(response["totalLiabilityOfQuoteCurrency"]);
          $("[href='#tab-isolated']").html(
            `Isolated<br>${document.amountToCurrency(netIsolatedValue, 1)}`
          );
          if (TRADING.assets) {
            TRADING.assets.forEach(function (asset) {
              if (asset["type"] == "main" || asset["type"] == "trade") {
                netIsolatedValue += Number(asset["usd_value"]);
              }
            });
          }
          $("[href='#tab-assets']").html(
            `Assets<br>${document.amountToCurrency(netIsolatedValue, 1)}`
          );
          $("head title").text(
            `${document.amountToCurrency(netIsolatedValue, 1)} | trading`
          );
        } catch (err) {
          document.showAlertDanger(err);
        }
      },
      refreshChallengeTableInterval: () => {
        clearInterval(TRADING.updateChallengeTable);
        TRADING.updateChallengeTable = setInterval(function () {
          TRADING.refreshChallengeTable();
        }, 4 * 60 * 60_000);
      },
      refreshIsolatedTableInterval: () => {
        clearInterval(TRADING.updateIsolatedTable);
        TRADING.updateIsolatedTable = setInterval(function () {
          TRADING.refreshIsolatedTable();
        }, 60_000);
      },
      refreshTable: async () => {
        try {
          $.ajax({
            url: `/interval_pool_trading_alert_condition_records/trading_table`,
          });
        } catch (err) {
          document.showAlertDanger(err);
        }
      },
      refreshTableInterval: () => {
        clearInterval(TRADING.updateTable);
        TRADING.updateTable = setInterval(function () {
          TRADING.refreshTable();
        }, 60_000);
      },
      refreshAssetsTable: async () => {
        try {
          let response = await $.ajax({
            url: `/credentials/5/assets`,
          });
          TRADING.assetsTable.clear();
          TRADING.assetsTable.rows.add(response);
          TRADING.assetsTable.columns.adjust().draw();
          TRADING.assets = response;
          let value = 0;
          TRADING.assets.forEach(function (asset) {
            value += Number(asset["usd_value"]);
          });
          $("[href='#tab-assets']").html(
            `Assets<br>${document.amountToCurrency(value, 1)}`
          );
          $("head title").text(
            `${document.amountToCurrency(value, 1)} | trading`
          );
        } catch (err) {
          document.showAlertDanger(err);
        }
      },
      refreshAssetsTableInterval: () => {
        clearInterval(TRADING.updateAssetsTable);
        TRADING.updateAssetsTable = setInterval(function () {
          TRADING.refreshAssetsTable();
        }, 60_000);
      },
      refreshIntervalPoolTradingAlertConditionRecordsInterval: () => {
        clearInterval(TRADING.updateIntervalPoolTradingAlertConditionRecords);
        TRADING.updateIntervalPoolTradingAlertConditionRecords = setInterval(
          function () {
            TRADING.refreshIntervalPoolTradingAlertConditionRecords();
          },
          60_000
        );
      },
      refreshIntervalStatsTable: async () => {
        try {
          $.ajax({
            url: `/iptacr_results/stats_table`,
          });
        } catch (err) {
          document.showAlertDanger(err);
        }
      },
      refreshIntervalStatsTableInterval: () => {
        clearInterval(TRADING.updateIntervaleStatsTable);
        TRADING.updateIntervaleStatsTable = setInterval(function () {
          TRADING.refreshIntervalStatsTable();
        }, 24 * 60 * 60_000);
      },
    };
    TRADING.init();
  }
});
